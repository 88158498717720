<template>
  <div
    @click="closePopUp()"
    class="fixed w-full h-full transparent-background blurry-background"
  ></div>
  <div class="fixed w-11/12 md:w-1/2 bg-white top-1/3 left-4 md:left-1/3 p-5">
    <div v-if="!showFreeTrial">
      <div class="loader m-auto"></div>
    </div>
    <div v-else>
      <div e class="flex justify-end">
        <p @click="closePopUp()" class="text-2xl font-bold cursor-pointer">
          &times;
        </p>
      </div>
      <div class="text-center">
        <img class="m-auto" src="@/assets/icons/free-trial-icon.svg" alt="" />
        <h1 class="mt-5 text-xl font-semibold">Free Trial</h1>

        <h1 class="w-1/2 m-auto mt-5 mb-9 font-bold">
          Your free trial is ending in 30 days. We hope you enjoy it! After this
          time we will automatically charge your account.
        </h1>

        <router-link
          class="p-2 font-semibold bg-mindaro my-5"
          :to="{ name: 'Buy seats', hash: '#trial' }"
        >
          Get free trial
        </router-link>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref, onMounted } from "vue";
import { getFreeTrial } from "@/services/location/location.js";

const router = useRouter();

const freeTrialInfo = ref(null);
const showFreeTrial = ref(false);

onMounted(async () => {
  freeTrialInfo.value = await getFreeTrial();
  validateFreeTrial();
});

function closePopUp() {
  router.back();
}

function validateFreeTrial() {
  const freeTrialEndDate = new Date(freeTrialInfo.value.date);
  const currentDate = new Date();

  if (freeTrialInfo.value.used_free_trial) {
    if (currentDate > freeTrialEndDate) {
      return router.push({
        name: "Buy seats",
      });
    }
  }

  showFreeTrial.value = true;
}
</script>

<style scoped>
.blurry-background {
  filter: blur(5px);
}

.transparent-background {
  background: rgba(0, 0, 0, 0.5);
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
